<script>
import LegoTooltip from "@/components/Lego/LegoTooltip.vue";
import LegoControlCardDate from "@/components/Lego/LegoControlCardDate.vue";
import LegoControlCardDone from "@/components/Lego/LegoControlCardDone.vue";
import LegoControlCardEffort from "@/components/Lego/LegoControlCardEffort.vue";
import LegoControlCardAuditoria from "@/components/Lego/LegoControlCardAuditoria.vue";
// import LegoProgressBar from '@/components/Lego/LegoProgressBar.vue';
import VSAvatarGroup from "@/components/Tooltip2Vue/VSAvatarGroup.vue";
import progressBubble from "@/components/progress/progressBubble.vue";
export default {
  components: {
    LegoTooltip,
    LegoControlCardDate,
    LegoControlCardDone,
    LegoControlCardEffort,
    LegoControlCardAuditoria,
    VSAvatarGroup,
    progressBubble,
    // LegoProgressBar
  },
  props: {
    card: {
      type: Object,
      required: true,
    },
    disabledControls: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    extractDescription(text) {
      // Expresión regular para capturar el contenido dentro de cualquier etiqueta HTML
      return text.match(/<[^>]+>(.*?)<\/[^>]+>/)?.[1];
    },
    openCard() {
      this.$router.push({
        name: "LegoTarjeta",
        params: { cardId: this.card.id },
      });
    },
    deleteCard() {
      this.$store.dispatch("lego/deleteCard", this.card);
    },
  },
};
</script>

<template>
  <div class="card-resumen" @click="openCard">
    <header class="header">
      <div>
        <div class="card-resumen--title">
          <span>
            {{ card.title }}
          </span>
        </div>
        <span v-show="card.description" class="description">
          <i class="fas fa-arrow-turn-up mr-1" style="rotate: 90deg"></i>
          {{ card.description ? extractDescription(card.description) : "" }}
        </span>
      </div>
      <div @click.stop class="controls">
        <LegoTooltip position="bottom">
          <!-- <span>Relaciones</span> -->
          <span class="text-lego-danger" @click="deleteCard"
            >Eliminar tarjeta</span
          >
        </LegoTooltip>
      </div>
    </header>
    <div @click.stop class="tags-container" v-if="!disabledControls">
      <LegoControlCardDone :card="card" />
      <LegoControlCardDate
        :card="card"
        position="right-start"
        v-if="card.startDate || card.dueDate"
      />
      <LegoControlCardEffort :card="card" v-if="card.effort" />
      <LegoControlCardAuditoria
        :card="card"
        v-if="card.legoAuditoriaId"
        position="right-start"
      />
      <!-- <LegoProgressBar :list="(card.checklists.map( c => c.items).flat())" class="w-100" :color="card.color" /> -->
    </div>
    <div class="tags-container" v-else>
      <LegoControlCardDone disabled :card="card" />
      <LegoControlCardDate
        disabled
        :card="card"
        position="right-start"
        v-if="card.startDate || card.dueDate"
      />
      <LegoControlCardEffort disabled :card="card" v-if="card.effort" />
      <LegoControlCardAuditoria
        disabled
        :card="card"
        v-if="card.legoAuditoriaId"
        position="right-start"
      />
    </div>

    <div>
      <hr />
      <div
        class="d-flex justify-content-between align-items-center"
        style="gap: 0.3rem"
      >
        <!-- progreso  -->
        <progress-bubble
          v-b-tooltip.hover
          title="Progreso"
          size="sm"
          :percent="card.progreso"
        />
        <div class="d-flex flex-column align-items-end">
          <!-- responsables avatars -->
          <VSAvatarGroup
            position="bottom"
            :listAvatars="
              card.assignments?.map((assignment) => assignment.collaborator)
            "
            class="mb-2"
          />
          <div
            v-b-tooltip
            :title="$moment(card.createdAt).format('DD MMM, YYYY hh:mm a')"
            class="text-white-50"
          >
            <i class="fas fa-calendar-days"></i>
            {{ card.createdAt | moment("from", "now") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card-resumen {
  background-color: var(--lego-oscuro);
  box-shadow: 0 2px 2px 0.1px var(--lego-shadow);
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  /* margin: 0.5rem 0; */
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1),
    box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1), margin 0.3s ease;
  .dark & {
    background-color: var(--lego-bg);
  }
}

.card-resumen:hover {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
  .dark & {
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.25);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0.5rem;
}

.controls {
  opacity: 0;
  transition: 0.3s;
}

.card-resumen:hover .controls {
  opacity: 1;
}

.card-resumen--title {
  font-size: 1.1rem;
  font-weight: bold;
}

.tags-container {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.description {
  color: rgb(143, 143, 143);
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4; /* Add this line for compatibility */
  -webkit-box-orient: vertical;
  box-orient: vertical; /* Add this line for compatibility */
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
